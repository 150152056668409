<script>
export default {
  methods: {
    deleteAccount() {
      this.$buefy.dialog.prompt({
        message: `
          Confirmez la suppression de votre compte
          en renseignant votre mot de passe.
        `,
        type: 'is-danger',
        inputAttrs: { type: 'password' },
        closeOnConfirm: false,
        onConfirm: ((password, { close }) => {
          const loader = this.$buefy.loading.open();
          this.$store.dispatch('auth/delete', { password })
            .then(() => {
              this.$showMessage.success();
              close();
            })
            .catch((error) => this.$errorHandlers.axios(error))
            .finally(() => loader.close());
        }),
      });
    },
  },
};
</script>

<template>
  <div class="box is-flex is-justify-content-space-between is-align-items-center">
    <div class="content">
      <h2 class="is-size-6">
        Suppression du compte
      </h2>
      <p>
        La suppression de votre compte est définitive.
      </p>
    </div>
    <b-button type="is-danger" outlined @click="deleteAccount">
      Supprimer mon compte
    </b-button>
  </div>
</template>
